<template>
    <li>
        <prime-button
            icon="pi pi-shopping-cart"
            text
            severity="secondary"
            aria-label="Cart"
            class="block sm:hidden"
            :label="activeFacility.shortName"
            :badge="badge"
            badge-severity="secondary"
            @click="setCartVisible(true)"
        />

        <prime-button
            icon="pi pi-shopping-cart"
            text
            severity="secondary"
            aria-label="Cart"
            class="hidden sm:inline-flex"
            :badge="badge"
            badge-severity="secondary"
            @click="setCartVisible(true)"
        />
    </li>
</template>

<script setup lang="ts">
import {
    setCartVisible,
    useCartData,
    useViewerCartQuery
} from '~/composables/use-cart'
import PrimeButton from 'primevue/button'
import { computed } from 'vue'
import { useActiveFacility } from '~/composables/use-active-facility'

useViewerCartQuery()
const activeFacility = useActiveFacility()
const cartData = useCartData()
const badge = computed(() => {
    if (cartData.value === undefined || cartData.value.itemCount === 0) {
        return ''
    } else {
        return cartData.value.itemCount.toString()
    }
})
</script>
