<template>
    <nav
        role="navigation"
        class="h-full w-full"
    >
        <prime-tree
            :value="treeItems"
            :pt="{
                root: {
                    class: 'border-0 !p-0 !w-full !h-full !max-w-full'
                },
                wrapper: {
                    class: 'h-full'
                },
                node: {
                    class: '!p-0'
                },
                content: {
                    class: 'border-b !rounded-none'
                },
                toggler: {
                    class: 'order-last'
                },
                label: {
                    class: 'flex-1'
                }
            }"
            :pt-options="{
                mergeProps: true
            }"
        >
            <template #default="{ node }">
                <nuxt-link
                    v-if="node.key === 'sign-in'"
                    class="flex items-center gap-2"
                    :to="facilitySignInLink(activeFacility.slug)"
                >
                    <i class="pi pi-user" />
                    Sign In
                </nuxt-link>

                <a
                    v-else-if="node.key === 'sign-out'"
                    class="flex items-center gap-2"
                    href="javascript:void(0)"
                    @click.prevent.stop="signOut"
                >
                    <i class="pi pi-sign-out" />
                    Sign Out
                </a>

                <contact-us-button
                    v-else-if="node.key === 'contact-us'"
                    v-slot="{ loading, handleClick }"
                    :link-text="node.label"
                >
                    <a
                        class="flex items-center gap-2"
                        href="javascript:void(0)"
                        @click.prevent.stop="handleClick"
                    >
                        <i
                            v-if="loading"
                            class="pi pi-question-circle"
                        />
                        <i
                            v-else
                            class="pi pi-question-circle"
                        />
                        {{ node.label }}
                    </a>
                </contact-us-button>

                <nuxt-link
                    v-else-if="node.key === 'viewer'"
                    class="flex items-center gap-2"
                    :to="profileLink()"
                >
                    <i class="pi pi-user" />
                    {{ node.label }}
                </nuxt-link>

                <nuxt-link
                    v-else
                    :to="node.url"
                    class="flex"
                    @click="emit('close')"
                >
                    {{ node.label }}
                </nuxt-link>
            </template>

            <template #togglericon="{ expanded }">
                <i
                    v-if="expanded"
                    class="pi pi-minus"
                />
                <i
                    v-else
                    class="pi pi-plus"
                />
            </template>
        </prime-tree>
    </nav>
</template>

<script setup lang="ts">
import type {
    StorefrontCatalogScalar,
    StorefrontNode
} from '~/resources/scalars/storefront-catalog-scalar'
import PrimeTree from 'primevue/tree'
import { computed } from 'vue'
import {
    useLogoutMutation,
    useSetViewer,
    useViewer
} from '~/composables/use-login'
import { useSetCartData } from '~/composables/use-cart'
import { useRouter } from 'vue-router'
import { useActiveFacility } from '~/composables/use-active-facility'
import { useToast } from 'primevue/usetoast'
import { useRouteLink } from '~/composables/routing'
import { useQuery } from '@tanstack/vue-query'
import { graphql } from '~/resources/graphql'
import { useRpQuery } from '~/composables/graphql'
import { useLayoutMode } from '~/composables/use-layout-mode'
import ContactUsButton from '~/components/Layouts/ContactUsButton.vue'

const props = defineProps<{
    catalog: StorefrontCatalogScalar
    storefrontPrefix: string
}>()

const emit = defineEmits<{
    (e: 'close'): void
}>()

type ItemNode = {
    key: string
    label: string
    level: number
    url: string
    type:
        | 'StorefrontPlan'
        | 'StorefrontCalendar'
        | 'StorefrontCalendarGroup'
        | 'StorefrontCatalog'
    children: ItemNode[]
}

const { isLightbox } = useLayoutMode()

const {
    facilityHomeLink,
    profileLink,
    facilitySignInLink,
    facilityCreateContractLink,
    storefrontNodeLink,
    storefrontCalendarGroupLink
} = useRouteLink()
// Map a node to a menu item
function mapNodeToMenuItem(
    node: StorefrontNode,
    parent: StorefrontNode,
    level: number
): ItemNode {
    if (node.__typename === 'StorefrontPlan') {
        return {
            key: node.slug,
            label: node.title,
            level,
            type: 'StorefrontPlan',
            url: facilityCreateContractLink(
                props.storefrontPrefix,
                node.planType,
                node.slug
            ),
            children: []
        }
    } else if (node.__typename === 'StorefrontCalendar') {
        return {
            key: node.slug,
            label: node.title,
            level,
            type: 'StorefrontCalendar',
            url: storefrontNodeLink(props.storefrontPrefix, node.slug),
            children: node.children
                .filter(child => child.showInLinkBar)
                .map(child => mapNodeToMenuItem(child, node, level + 1))
        }
    } else if (node.__typename === 'StorefrontCalendarGroup') {
        return {
            key: node.slug,
            label: node.title,
            level,
            type: 'StorefrontCalendarGroup',
            url: storefrontCalendarGroupLink(
                props.storefrontPrefix,
                parent.slug,
                node.slug
            ),
            children: node.displayChildren
                ? node.children
                      .filter(child => child.showInLinkBar)
                      .map(child => mapNodeToMenuItem(child, node, level + 1))
                : []
        }
    }

    return {
        key: node.slug,
        label: node.title,
        level,
        type: 'StorefrontCatalog',
        url: storefrontNodeLink(props.storefrontPrefix, node.slug),
        children: node.children
            .filter(child => child.showInLinkBar)
            .map(child => mapNodeToMenuItem(child, node, level + 1))
    }
}

const treeItems = computed<ItemNode[]>(() => {
    const root = props.catalog
    if (root === null) {
        return []
    }

    const activeFacilities =
        data.value?.facilities.edges
            .filter(edge => {
                return (
                    edge.node.active === true && edge.node.storefront !== null
                )
            })
            .map(edge => ({
                key: 'facility-' + edge.node.id,
                label: edge.node.shortName,
                url: facilityHomeLink(edge.node.slug.toLowerCase()),
                children: []
            })) ?? []

    return [
        viewer.value === undefined
            ? {
                  key: 'sign-in',
                  label: 'Sign In',
                  children: []
              }
            : {
                  key: 'viewer',
                  label: viewer.value.name,
                  children: []
              },
        ...(activeFacility.value.organization.contactUsLinkText
            ? [
                  {
                      key: 'contact-us',
                      label: activeFacility.value.organization
                          .contactUsLinkText,
                      children: []
                  }
              ]
            : []),
        ...(activeFacilities.length > 1
            ? [
                  {
                      key: 'facility',
                      label: 'Our Locations',
                      children: activeFacilities
                  }
              ]
            : []),
        ...root.children
            .filter(child => child.showInLinkBar)
            .map(child => mapNodeToMenuItem(child, root, 0)),
        ...(viewer.value === undefined
            ? []
            : [
                  {
                      key: 'sign-out',
                      label: 'Sign Out',
                      children: []
                  }
              ])
    ]
})

const viewer = useViewer()
const setViewer = useSetViewer()
const { mutate } = useLogoutMutation()

const setCartData = useSetCartData()
const router = useRouter()
const activeFacility = useActiveFacility()
const toast = useToast()
function signOut() {
    mutate(undefined, {
        onSuccess: data => {
            if (data.logout === true) {
                setViewer(undefined)
                setCartData(undefined)
                router.push(facilityHomeLink(activeFacility.value.slug))
                toast.add({
                    severity: 'info',
                    summary: 'Sign Out Complete',
                    life: 3000
                })
                emit('close')
            } else {
                throw new Error()
            }
        }
    })
}

const query = useRpQuery()
const { data } = useQuery({
    queryKey: ['OurLocationsQuery'],
    queryFn: () =>
        query(
            graphql(/* GraphQL */ `
                query OurLocationsQuery {
                    facilities(first: 30) {
                        edges {
                            node {
                                id
                                shortName
                                longName
                                slug
                                active
                                storefront {
                                    id
                                }
                            }
                        }
                    }
                }
            `)
        )
})
</script>
